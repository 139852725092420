import React, { useEffect, useState } from "react";
import { database, collection, getDocs } from "../firebase/firebase";
import ImageWithPlaceholder from "../components/ImageWithPlaceHolder";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const querySnapshot = await getDocs(collection(database, "blogs"));
        const blogsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          date: new Date(doc.data().date.seconds * 1000), // Convert the timestamp to a JS Date object
        }));
        setBlogs(blogsData);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <div
      className="container mt-5 bg-dark text-white"
      style={{ minHeight: "100vh" }}
    >
      <h1 className="mb-4">Blog Posts</h1>
      <div className="row">
        {blogs.map(({ id, title, content, author, date, imageUrl }) => (
          <div key={id} className="col-md-6 mb-4">
            <div className="card h-100 bg-light text-dark">
              <ImageWithPlaceholder imageUrl={imageUrl} title={title} />
              <div className="card-body d-flex flex-column">
                <h5 className="card-title">{title}</h5>
                <p className="card-text">{content}</p>
                <p className="text-muted mt-auto">
                  <strong>By:</strong> {author} <br />
                  <strong>Date:</strong> {date.toLocaleDateString()}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;
