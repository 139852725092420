import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import ImageWithPlaceholder from "../components/ImageWithPlaceHolder";
import { database, collection, getDocs } from "../firebase/firebase";

const Portfolio = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const querySnapshot = await getDocs(collection(database, "projects"));
        const projectsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProjects(projectsData);
      } catch (error) {
        console.error("Error fetching projects data:", error);
      }
    };

    fetchProjects();
  }, []);

  return (
    <div
      className="container mt-5 bg-dark text-white"
      style={{ minHeight: "100vh" }}
    >
      <h1 className="mb-4">Projects</h1>
      <div className="row">
        {projects.map((project) => (
          <div className="col-md-4" key={project.id}>
            <div className="card mb-4 bg-secondary text-white">
              <ImageWithPlaceholder
                imageUrl={project.image}
                title={project.name}
              />
              <div className="card-body" style={{ minHeight: "100px" }}>
                <h5 className="card-title">{project.name}</h5>
                <p className="card-text">{project.description}</p>
                <a
                  href={project.githubUrl}
                  className="btn btn-primary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faGithub}
                    size="1x"
                    style={{ marginRight: "4px" }}
                  />
                  Source code
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
