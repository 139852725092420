import React, { useState } from "react";
import loadingGif from "../assets/loading.gif";

const ImageWithPlaceholder = React.memo(({ imageUrl, title }) => {
  const [src, setSrc] = useState(loadingGif);
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
    setSrc(imageUrl);
  };

  const handleImageError = () => {
    setIsLoading(false);
    setSrc(loadingGif);
  };

  return (
    <img
      src={isLoading ? loadingGif : src}
      className="card-img-top image-placeholder"
      alt={title}
      onLoad={handleImageLoad}
      onError={handleImageError}
      style={{ height: "200px", objectFit: "-moz-initial" }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = "scale(1.05)";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = "scale(1)";
      }}
    />
  );
});

export default ImageWithPlaceholder;
