import React, { useEffect, useState } from "react";
import { database, collection, getDocs } from "../firebase/firebase";
import { Container, Image, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import personalPic from "../assets/main_photo.jpg";
import {
  faFacebook,
  faLinkedin,
  faInstagram,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import Typed from "typed.js";
import "../css/home.css";
import { Link } from "react-router-dom";

const Home = () => {
  const [admin, setAdmin] = useState();

  useEffect(() => {
    new Typed(".typed-text", {
      strings: ["Full Stack Java Developer"],
      typeSpeed: 50,
      loop: true,
    });
  }, []);

  useEffect(() => {
    const fetchAdmin = async () => {
      try {
        const querySnapshot = await getDocs(collection(database, "admin"));
        const adminData = querySnapshot.docs[0]?.data();
        setAdmin(adminData);
      } catch (error) {
        console.error("Error fetching admin data:", error);
      }
    };

    fetchAdmin();
  }, []);

  return (
    <main className="bg-dark text-light min-vh-100 d-flex flex-column justify-content-center">
      <Container className="d-flex flex-column flex-md-row align-items-center">
        <div className="mb-4 mb-md-0">
          <Image
            src={personalPic}
            width={"300px"}
            alt="Profile picture of Sai Nath"
            roundedCircle
            className="profile-pic"
            fluid
          />
        </div>
        <div className="ms-md-5 text-center text-md-start">
          <h5 className="text-muted">
            <span className="text-white">Hi I'm </span>{" "}
            <span className="typed-text text-primary"></span>
          </h5>
          <h1 className="text-primary">Sai Nath</h1>
          <p>
            I am eager to contribute my technical skills and passion for
            software development to a dynamic team. My proficiency in various
            frameworks and libraries positions me to excel as a Full Stack Java
            Developer. I am committed to continuous learning and delivering
            high-quality software products or services in the projects I
            undertake.
          </p>
          <div className="d-flex justify-content-center justify-content-md-start mb-4">
            <a
              href={admin?.resumeUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button variant="outline-primary" className="me-2">
                View Resume
              </Button>
            </a>
            <Link to="/contact" className="text-light me-3">
            <Button variant="outline-primary">Contact Me</Button>
            </Link>
          </div>
          <div className="d-flex justify-content-center justify-content-md-start">
            <a
              href="https://www.facebook.com/profile.php?id=100037730351560"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light me-3"
              style={{ transition: "transform 1s", display: "inline-block" }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "rotate(360deg)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "rotate(0deg)")
              }
            >
              <FontAwesomeIcon icon={faFacebook} size="2x" />
            </a>
            <a
              href="https://www.linkedin.com/in/sainath-kunapareddy-89810731b/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light me-3"
              style={{ transition: "transform 1s", display: "inline-block" }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "rotate(360deg)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "rotate(0deg)")
              }
            >
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>
            <a
              href="https://www.instagram.com/_sainath_ig"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light me-3"
              style={{ transition: "transform 1s", display: "inline-block" }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "rotate(360deg)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "rotate(0deg)")
              }
            >
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
            <a
              href="https://github.com/kunapareddysainath"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
              style={{ transition: "transform 1s", display: "inline-block" }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.transform = "rotate(360deg)")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.transform = "rotate(0deg)")
              }
            >
              <FontAwesomeIcon icon={faGithub} size="2x" />
            </a>
          </div>
        </div>
      </Container>
    </main>
  );
};

export default Home;
