import React from "react";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faS } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";

const Header = () => {
  return (
    <header className=" d-flex bg-dark text-light py-3">
      <Container className="d-flex justify-content-between align-items-center">
        <Link to="/" className="text-decoration-none text-light">
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={faS}
              className="bg-primary text-white rounded-circle p-3 me-2"
            />
            <h1 className="h4 mb-0">Sai Nath</h1>
          </div>
        </Link>
      </Container>
      <Navbar />
    </header>
  );
};

export default Header;
