import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Master from "./components/Master";

function App() {
  return (
    <div className="App bg-dark">
      <Master />
    </div>
  );
}

export default App;
