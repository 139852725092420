// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCofqSOWflBoshfddPBa4Haqp3qWO0twMk",
  authDomain: "personal-website-b8924.firebaseapp.com",
  projectId: "personal-website-b8924",
  storageBucket: "personal-website-b8924.appspot.com",
  messagingSenderId: "563182002832",
  appId: "1:563182002832:web:96b1b4eb530cde52caae15",
  measurementId: "G-MKSGPGP8F0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getFirestore(app);
export {database, getDocs, collection};
