import Lottie from "lottie-react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import contactAnimation from "../assets/contact-animation.json";

const Contact = () => {
  return (
    <div className="contact d-flex flex-column flex-lg-row bg-dark text-white py-5" style={{ minHeight: "100vh" }}>
      <div
        className="contact-animation d-flex justify-content-center align-items-center flex-grow-1"
        style={{ maxWidth: "100%", padding: "0 20px", marginBottom: "20px" }}
      >
        <Lottie
          animationData={contactAnimation}
          style={{ width: "100%", height: "auto" }}
        />
      </div>
      <div
        className="contact-form d-flex flex-column justify-content-center align-items-start flex-grow-1"
        style={{ maxWidth: "100%", padding: "0 20px" }}
      >
        <h2 className="mb-4">Get in Touch</h2>
        <ul className="list-unstyled">
          <li className="d-flex align-items-center mb-3">
            <FontAwesomeIcon icon={faMapMarkerAlt} size="lg" className="me-2" />
            <span>
              14-20-677/418/B, Sri Vivekananda Nagar, Allapur, Borabanda,
              Hyderabad – 500018.
            </span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <FontAwesomeIcon icon={faEnvelope} size="lg" className="me-2" />
            <span>kunapareddysainath2311@gmail.com</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <FontAwesomeIcon icon={faPhone} size="lg" className="me-2" />
            <span>(+91) 7981252711</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <FontAwesomeIcon icon={faInstagram} size="lg" className="me-2" />
            <span>_sainath_ig</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Contact;
